


const env = new class {
  isDev: boolean
  isProd: boolean | string
  isStaging: boolean | string

  API_URL: string
  DOMAIN: string

  staticPath: (...args: any[]) => void
  staticUrl: (...args: any[]) => void

  cdnStore: (slug: string) => string

  constructor() {
    // this.VERSION = 1 // process.env.npm_package_version || '0'

    this.API_URL = process.env.DJANGO_API_URL || ''
    this.DOMAIN = process.env.NEXTAUTH_URL || ''

    this.isDev = process.env.IS_DEV || process.env.NODE_ENV !== 'production' ? true : false
    this.isProd = process.env.isProd || !this.isDev

    this.isStaging = process.env.isStaging || false

    this.staticPath = (...v) => `/static/${v.join('/')}`
    this.staticUrl = (...v) => this.DOMAIN + this.staticPath(...v)

    this.cdnStore = (slug: string) => `${process.env.CDN_STORE_URL}/${slug}`

  }

}

export default env
