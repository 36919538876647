

// mui
import Box from '@mui/material/Box';

// @components
import { Link } from '@components/Link'
import { Typography } from "@mui/material";

import { menuArticles } from '../menuList'


export default function Articles() {

  const items = menuArticles

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 5,
    }}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            flexGrow: 1,
            // ml: 3,
            color: 'text.primary',
            // bgcolor: 'rgba(0, 0, 0, 0.05)',
            // borderRadius: 1,
            // px: 5,
            // py: 10,
          }}>
          <Typography variant="h6" color="inherit" gutterBottom sx={{
            textTransform: 'uppercase',
            fontSize: '1.2rem',
          }}>
            {item.title}
          </Typography>
          <Box>
            {item.links.map((link, i) => (
              <Link
                key={`${index}-${i}`}
                href={link.href}
                variant="subtitle1"
                color="primary"
                sx={{
                  display: 'block',
                  py: 1,
                }}
              >
                {link.label}
              </Link>
            ))}
          </Box>
          {/* <Box>
            <Link
              href={'#'}
              variant="subtitle1"
              color="primary"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Blog on title
            </Link>
          </Box> */}
        </Box>
      ))}
    </Box>
  )
}
