import { useState } from 'react';

// @core
import { useEstate } from '@core/api';

// mui
import { useTheme, Theme, alpha } from '@mui/material/styles';
import { Container, Box, TextField, Button, Typography, Divider } from "@mui/material";



export default function Subscription() {

  // hooks
  const theme = useTheme<Theme>();
  const estate = useEstate();

  // states
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);


  const handleSubscribe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    estate.SUBSCRIPTION.create({ email }).then((res) => {
      if (res.status === 201) {
        setSuccess(true)
      }
    })
  }

  const renderForm = () => {
    return (
      <Box
        component='form'
        onSubmit={handleSubscribe}
        sx={{
          display: 'flex',
          gap: 2,
        }}>
        <TextField
          required
          placeholder="Enter your email"
          type="email"
          label="Subscribe"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{
            width: {
              xs: '100%',
              md: 300,
            },
          }}
        />
        <Box>
          <Button
            size="large"
            type="submit"
            variant="contained" color="primary">
            Subscribe
          </Button>
        </Box>
      </Box>
    )
  }

  if (success) {
    return (
      <Box>
        <Typography variant="h6">
          Thank you for subscribing to our newsletter.
        </Typography>
      </Box>
    )
  }

  return renderForm()
}