// hooks
import AxiosApi from '../AxiosApi';

// @configs
import catalogCfg from '@configs/catalogCfg';

// types
import type {
  IPropertySearch,
  ResponsePageMain,
  ResponseComplexCard,
  ResponseComplexList,
  ResponsePropertyCard,
  ResponsePropertyResults,
  ResponseContextResults,
  ResponseCategoriesList,
  ResponseCategoryDetail,
  ResponseDestinationsList,
  ResponseDestinationCountryDetail,
  ResponseDestinationSublocationDetail,
} from './types';

import { AxiosResponse } from 'axios';

interface ResponsePromises<T> extends Promise<AxiosResponse<T>> {
  data: T;
  status: number;
}

export default function useEstate() {
  const api = new AxiosApi();

  const CONTEXT = {
    main: () => {
      const url = `/djapi/estate/context/`;
      return api.get(url) as ResponsePromises<ResponseContextResults>;
    }
  }

  const PROPERTY = {
    search: ({
      page = 1,
      limit = catalogCfg.MAX_PRODUCT_PER_PAGE,
      ordering = catalogCfg.DEFAULT_SORT,
      filter = {},
    }: IPropertySearch) => {

      if (typeof filter === 'object') {
        filter = JSON.stringify(filter)
      }

      const query = { page, limit, ordering, filter }

      const url = `/djapi/estate/property/search/`;
      return api.get(url, query) as ResponsePromises<ResponsePropertyResults>;
    },
    searchCategory: (slug: string) => {
      const query = {
        page: 1,
        limit: catalogCfg.MAX_PRODUCT_PER_PAGE
      }
      const url = `/djapi/estate/property/search/category/${slug}/`;
      return api.get(url, query) as ResponsePromises<ResponsePropertyResults>;
    },
    card: (slug: string) => {
      const url = `/djapi/estate/property/card/${slug}/`;
      return api.get(url) as ResponsePromises<ResponsePropertyCard>;
    }
  }

  const COMPLEX = {
    list: (page: number = 1, limit: number = 100) => {
      const query = { page, limit }
      const url = `/djapi/estate/complexes/list/`;
      return api.get(url, query) as ResponsePromises<ResponseComplexList>;
    },
    card: (slug: string) => {
      const url = `/djapi/estate/complexes/card/${slug}/`;
      return api.get(url) as ResponsePromises<ResponseComplexCard>;
    }
  }

  const PAGES = {
    main: () => {
      const url = `/djapi/estate/pages/main/`;
      return api.get(url) as ResponsePromises<ResponsePageMain>;
    },
  }

  const CATEGORIES = {
    list: (page: number = 1, limit: number = 100) => {
      const query = { page, limit }
      const url = `/djapi/estate/categories/`;
      return api.get(url, query) as ResponsePromises<ResponseCategoriesList>;
    },
    detail: (slug: string) => {
      const url = `/djapi/estate/category/${slug}/`;
      return api.get(url) as ResponsePromises<ResponseCategoryDetail>;
    }
  }


  const DESTINATIONS = {
    list: (page: number = 1, limit: number = 100) => {
      const query = { page, limit }
      const url = `/djapi/estate/destinations/`;
      return api.get(url, query) as ResponsePromises<ResponseDestinationsList>;
    },
    countryDetail: (slug: string) => {
      const url = `/djapi/estate/destinations/country/${slug}/`;
      return api.get(url) as ResponsePromises<ResponseDestinationCountryDetail>;
    },
    sublocationDetail: (slug: string) => {
      const url = `/djapi/estate/destinations/sublocation/${slug}/`;
      return api.get(url) as ResponsePromises<ResponseDestinationSublocationDetail>;
    },
  }

  const DEVELOPERS = {
    detail: (slug: string) => {
      const url = `/djapi/estate/developers/${slug}/`;
      return api.get(url);
    },
  }

  const CONTACT_FORM = {
    create: (data: any) => {
      const url = `/djapi/contact_form/create/`;
      return api.post(url, data);
    },
    formUpdate: (formId: number, data: any) => {
      const url = `/djapi/contact_form/form/${formId}/`;
      return api.put(url, data);
    },
  }

  const SUBSCRIPTION = {
    create: (data: any) => {
      const url = `/djapi/subscription/create/`;
      return api.post(url, data);
    },
  }

  return {
    CONTEXT,
    PROPERTY,
    COMPLEX,
    PAGES,
    CATEGORIES,
    DESTINATIONS,
    DEVELOPERS,
    CONTACT_FORM,
    SUBSCRIPTION,
  }
};

