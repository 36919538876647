import { useState } from 'react'
import NextLink from 'next/link'

// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";
import SvgLogo from '@snippets/Logotype/SvgLogo'

// @hooks
import useMedia from '@hooks/useMedia';

// @configs
import routes from '@configs/routes'
import project from "@configs/project";

// mui
import { Box, Container, Grid, Stack, Typography, Button, Link, Divider } from '@mui/material'
import Collapse from '@mui/material/Collapse';

// icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// libs
import moment from "moment";

// blocks
import Subscription from './Subscription'

// data
import { menuBuying, menuArticles, menuServices, menuCompany } from '@core/layouts/AppLayout/menuList'

interface ListProps {
  data: MenuProps
}

const List = ({ data }: ListProps) => {

  // hooks
  const media = useMedia()

  // states
  const [open, setOpen] = useState(false)

  // computed
  const splitAfter = 10
  const divideTwoColumns = (items: MenuItem[]) => {
    const items1 = items.slice(0, splitAfter)
    const items2 = items.slice(splitAfter)
    return {
      items1,
      items2,
    }
  }
  const { items1, items2 } = divideTwoColumns(data.items)

  const renderItems = (items: MenuItem[]) => {

    return (
      <Stack spacing={2}>
        {items.map((item, index) => (
          <Link
            key={index}
            href={item.href}
            color="text.primary">
            <Typography variant="subtitle1">
              {item.label}
            </Typography>
          </Link>
        ))}
      </Stack>
    )
  }

  if (media.down.md) {
    return (
      <Box>
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            py: 2,
          }}>
          <Typography variant="button" color="text.secondary">
            {data.title}
          </Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
          }}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </Box>
        </Box>
        <Collapse in={open}>
          <Box sx={{
            pb: 5,
          }}>
            {renderItems(items1)}
            {renderItems(items2)}
          </Box>
        </Collapse>
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant="button" color="text.secondary">
        {data.title}
      </Typography>
      <Box sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
        pt: 5,
        gap: 3,
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          flexGrow: 1,
        }
      }}>
        {renderItems(items1)}
        {renderItems(items2)}
      </Box>
    </Box>
  )
}

interface MenuItem {
  label: string
  href: string
}

interface MenuProps {
  title: string
  items: MenuItem[]
}

interface FooterProps {
  size?: 'hidden' | 'small' | 'default'
}

export default function View({ size = 'default' }: FooterProps) {

  // hooks
  const [t, { languages }] = useTranslate();

  let menuList: MenuProps[] = []

  menuBuying.forEach(item => {
    menuList.push({
      title: item.title,
      items: item.links.map(link => ({
        label: link.label,
        href: link.href,
      }))
    })
  })

  menuArticles.forEach(item => {
    menuList.push({
      title: item.title,
      items: item.links.map(link => ({
        label: link.label,
        href: link.href,
      }))
    })
  })

  menuList.push({
    title: 'Services',
    items: menuServices.map(link => ({
      label: link.title,
      href: link.href,
    }))
  })

  menuList.push({
    title: 'Company',
    items: menuCompany.map(link => ({
      label: link.title,
      href: link.href,
    }))
  })

  if (size === 'hidden') {
    return null
  }

  if (size === 'small') {
    return (
      <Box sx={{
        bgcolor: 'background.paper',
        py: 5,
        mt: 20,
      }}>
        <Container maxWidth="xl">

          <Grid container spacing={5}>
            <Grid item flexGrow={1}>
              <SvgLogo />
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={5}>

                <Link
                  component={NextLink}
                  href={routes.PAGES.HOME}
                  color="text.secondary">
                  <Typography variant="subtitle2">
                    {t(tDict.common.menu.main)}
                  </Typography>
                </Link>

                {/* <Link
                  href={routes.PAGES.HELP}
                  color="text.secondary">
                  <Typography variant="subtitle2">
                    {t(tDict.common.menu.helpCenter)}
                  </Typography>
                </Link> */}

              </Stack>
            </Grid>
          </Grid>

        </Container>
      </Box>
    )
  }

  return (
    <Box sx={{
      bgcolor: 'background.paper',
      color: 'text.primary',
      mt: {
        xs: 10,
        md: 20,
      },
      // pt: {
      //   xs: 10,
      //   md: 15,
      // }
    }}>

      <Container maxWidth="xl">
        <Box sx={{
          py: 10,
          display: 'flex',
          width: '100%',
          justifyContent: {
            xs: 'flex-start',
            md: 'space-between',
          },
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}>
          <Box>
            <SvgLogo
              component={NextLink}
              href="/"
              sx={{
                width: 150,
                mb: 5,
              }} />

            {/* <Box sx={{
              pt: 5,
            }}>
              <Typography variant='subtitle1' color="text.secondary" sx={{
                fontWeight: theme => theme.typography.fontWeightBold,
              }}>
                {project.contacts.name}
              </Typography>
              <Typography variant='subtitle1'>
                <Link href={`mailto:${project.contacts.email}`}>
                  {project.contacts.email}
                </Link>
              </Typography>
            </Box> */}
          </Box>

          <Subscription />

        </Box>

        <Box sx={{
          pb: 5,
        }}>
          <Divider />
        </Box>

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(4, 1fr)',
          },
          columnGap: 10,
          rowGap: 5,
        }}>

          {menuList.map((item, index) => (
            <List
              key={index}
              data={item} />
          ))}

        </Box>

        <Box sx={{
          pt: 30,
          pb: 10,
          display: 'flex',
          justifyContent: 'space-between',
          typography: 'subtitle2',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}>
          <Box>
            {/* ©{moment().year()} {project.contacts.name}, Inc. */}
            ©{moment().year()} Caribbean Real Estate MLS
          </Box>
          <Box sx={{
            display: 'flex',
            gap: 2,
          }}>
            <Link
              component={NextLink}
              color="text.secondary"
              href={routes.PAGES.PRIVACY}>
              Privacy Policy
            </Link>
            <Link
              component={NextLink}
              color="text.secondary"
              href={routes.PAGES.TERMS}>
              Terms of Service
            </Link>
          </Box>
        </Box>

      </Container>
    </Box>
  )
}