/* Usage

***** wrapper
// context
import ContextProvider from '@core/context';

return (
  <ContextProvider>
    {children...}
  </ContextProvider>
)

***** injection
// context
import { useGlobalContext, usePropertyContext } from '@core/context';

const globalContext = useGlobalContext()
const propertyContext = usePropertyContext()

*/

import GlobalProvider, { useGlobalContext } from './useGlobalContext'
import PropertyProvider, { usePropertyContext } from './usePropertyContext'

interface Props {
  pageProps: any
  children: JSX.Element
}

function ContextProvider({ children, ...props }: Props) {

  const pageProps = props.pageProps
  delete pageProps._nextI18Next
  delete pageProps._sentryTraceData
  delete pageProps._sentryBaggage

  return (
    <GlobalProvider pageProps={pageProps}>
      <PropertyProvider>
        {children}
      </PropertyProvider>
    </GlobalProvider>
  )
}

export { useGlobalContext, usePropertyContext }
export default ContextProvider
