
// libs
// https://viclafouch.github.io/mui-tel-input/
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'


interface Props extends MuiTelInputProps {

}

export default function InputPhone(props: Props) {

  return (
    <MuiTelInput {...props} />
  )
}