
import { useState } from 'react'

// @core
import { useEstate } from "@core/api";

// mui
import { Stack, Box, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

// @components
import FormikForm, { FormikValues, FormikMethods, Yup } from '@components/Formik/'
import InputPhone from "@components/MuiFields/InputPhone";

// blocks
import FormNext from './FormNext';


interface Props {
  propertyId?: string
  onSuccess: () => void
}

const Form = (props: Props) => {

  // hooks
  const estateApi = useEstate()

  // states
  const [contactFormId, setContactFormId] = useState<number | null>(null)

  const onSubmit = (values: any) => {

    // alert(JSON.stringify(values, null, 2))

    // Set property id
    values.property = props.propertyId

    return estateApi.CONTACT_FORM.create(values).then((res) => {
      if (res.status === 201) {
        setContactFormId(res.data.id)
      }
      return res
    })
  }

  if (contactFormId) {
    return (
      <FormNext
        contactFormId={contactFormId}
        onSuccess={props.onSuccess} />
    )
  }

  return (
    <Box>

      <Typography variant="h6" sx={{
        mb: 10,
      }}>
        Connect with our team of experts to general advice on buying, selling or investing in Caribbean real estate.
      </Typography>

      <FormikForm
        initialValues={{
          name: '',
          email: '',
          phone: '',
          address: '',
          country: '',

          // name: 'Mark',
          // email: 'mark@mark.com',
          // phone: '+34640111111',
          // address: 'Some address',
          // country: 'Some country',
        }}
        validationSchema={Yup.object({
          name: Yup
            .string()
            .required('Field is required'),
          email: Yup
            .string()
            .email('Invalid email address')
            .required('Field is required'),
          phone: Yup
            .string()
            .required('Field is required'),
          address: Yup
            .string()
            .required('Field is required'),
          country: Yup
            .string()
            .required('Field is required'),
        })}
        readOnLoad={false}
        onSubmit={onSubmit}
        onRead={undefined}
      >
        {(formik: FormikValues, methods: FormikMethods) => (
          <Stack spacing={5}>

            <TextField
              fullWidth
              required
              name="name"
              label="Full name"
              type="text"
              disabled={methods.disabled}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={methods.isError('name')}
              helperText={methods.helperText('name')}
            />

            <TextField
              fullWidth
              required
              name="email"
              label="Email"
              type="email"
              disabled={methods.disabled}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={methods.isError('email')}
              helperText={methods.helperText('email')}
            />

            <InputPhone
              fullWidth
              required
              name="phone"
              label="Phone"
              disabled={methods.disabled}
              value={formik.values.phone}
              onChange={(value) => {
                methods.setFieldValue('phone', value)
              }}
              error={methods.isError('phone')}
              helperText={methods.helperText('phone')}
            />

            <TextField
              fullWidth
              required
              name="address"
              label="Town/City"
              type="text"
              disabled={methods.disabled}
              value={formik.values.address}
              onChange={formik.handleChange}
              error={methods.isError('address')}
              helperText={methods.helperText('address')}
            />

            <TextField
              fullWidth
              required
              name="country"
              label="State/Country"
              type="text"
              disabled={methods.disabled}
              value={formik.values.country}
              onChange={formik.handleChange}
              error={methods.isError('country')}
              helperText={methods.helperText('country')}
            />

            <Button
              type="submit"
              color="primary"
              size="large"
              variant="contained" disabled={methods.disabled}>
              Confirm
            </Button>

          </Stack>
        )}

      </FormikForm>

    </Box>
  )
}

export default Form
