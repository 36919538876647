
// @configs
import routes from "@configs/routes";
import project from "@configs/project";

// mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// @components
import { ButtonLink } from '@components/Link'
import { Typography } from "@mui/material";
import IconifyIcon from "@components/IconifyIcon";

import { menuCompany } from '../menuList'

export default function Company() {

  const items = menuCompany

  return (
    <Box sx={{
      display: 'flex',
      gap: 20,
    }}>
      <Box sx={{
        width: 300,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Typography variant="subtitle1" align="center">
          The #1 source for Caribbean real estate listings, new developments, and resources to help buy, sell, renovate, and invest in property across the region.
        </Typography>
      </Box>
      <Box sx={{
        flexGrow: 1,
      }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 5,
        }}>
          {items.map((item, index) => (
            <ButtonLink
              key={index}
              href={item.href}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1,
                // '&:hover': {
                //   color: 'primary.main',
                // },
              }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                bgcolor: 'primary.main',
                width: 50,
                height: 50,
                borderRadius: 1,
              }}>
                <IconifyIcon icon={item.icon} />
              </Box>
              <Box sx={{
                flexGrow: 1,
                ml: 3,
                color: 'text.primary',
              }}>
                <Typography variant="h6" color="inherit" sx={{
                  textTransform: 'uppercase',
                }}>
                  {item.title}
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  {item.subtitle}
                </Typography>
              </Box>
              {/* <Box sx={{
                flexGrow: 1,
              }} />
              <ArrowForwardIcon /> */}
            </ButtonLink>
          ))}
        </Box>
      </Box>
      <Box>
        <ButtonLink
          href={routes.CATALOG.INDEX}
          size="large"
          variant="contained"
          color="primary">
          Show All Property
        </ButtonLink>
        <Box sx={{
          pt: 5,
        }}>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
            }}
          >
            <IconifyIcon icon="mdi:email-outline" />
            <Link
              variant="h6"
              color="inherit"
              href={`mailto:${project.contacts.email}`}
              target="_blank"
              sx={{
                ml: 3,
              }}>
              {project.contacts.email}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
