import { useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react';

// mui
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { Skeleton, Stack } from '@mui/material';

// libs
import { useFormik } from 'formik';
import type { FormikValues, FormikErrors, FormikConfig } from 'formik';
export type { FormikValues, FormikErrors }

import * as Yup from 'yup'; export { Yup }
import { AnySchema } from 'yup';

// blocks
import CustomSnack, { CustomSnackRefAccesss } from './CustomSnack';


interface FormikFormProps {
  readOnLoad: boolean;
  validate?: FormikConfig<FormikValues>['validate']
  onRead?: () => Promise<any>;
  onSubmit: (values: any) => Promise<any>;
  onSuccess?: () => void;
  children: (formik: any, methods: FormikMethods) => React.ReactNode;
  validationSchema: any;
  initialValues: { [key: string]: any };
  localStorageKey?: string;
  keepLocalStorage?: boolean;
  showSnackbar?: boolean;
}

export interface FormikRefAccess {
  setFieldValue: (key: string, value: any) => void;
  setErrors: (errors: { [key: string]: any }) => void;
  values: { [key: string]: any };
}

export interface FormikMethods {
  disabled: boolean;
  isError: (key: string) => boolean;
  helperText: (key: string, text?: string) => string;
  value?: string | number;
  onChange: Function;
  setFieldValue: (key: string, value: any) => void;
}

const FormikForm = forwardRef((props: FormikFormProps, ref: React.Ref<FormikRefAccess>) => {

  // refs
  const refCustomSnack = useRef<CustomSnackRefAccesss>(null);

  // states
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const localStorageKey = props.localStorageKey ? `formik-${props.localStorageKey}` : false;

  const formik = useFormik({
    validate: (values: FormikValues) => {
      if (props.validate) {
        return props.validate(values)
      }
    },
    initialValues: props.initialValues,
    validationSchema: props.validationSchema,
    onSubmit: async (values) => {
      setDisabled(true);

      await props.onSubmit(values)
        .then((res: any) => {
          if ([200, 201].includes(res.status)) {

            refCustomSnack.current?.open();

            if (typeof props.onSuccess === 'function') {
              props.onSuccess();
            }
            if (localStorageKey && !props.keepLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          } else {
            console.error('Error -> onSubmit ->', res.status, res.data);
            if ([403, 400].includes(res.status)) {
              const errors = Object.keys(res.data).reduce((acc: any, key: string) => {
                if (typeof res.data[key] === 'string') {
                  acc[key] = res.data[key];
                } else if (typeof res.data[key] === 'object') {
                  acc[key] = res.data[key][0];
                }
                return acc;
              }, {});
              formik.setErrors(errors);
            }
          }
          return res;
        })
        .catch((err: any) => {
          // formik.setErrors(res.body)
        });

      setDisabled(false);
    },
  });

  useImperativeHandle(ref, (): FormikRefAccess => ({
    setFieldValue: formik.setFieldValue,
    setErrors: formik.setErrors,
    values: formik.values,
  }));

  useEffect(() => {
    onLoad()
    if (!props.onRead) {
      setLoading(false)
    }
  }, [])

  const onLoad = async () => {
    if (!props.onRead || !props.readOnLoad) return;

    setDisabled(true);
    await props.onRead()
      .then((res: any) => {
        if (res.status === 200) {
          formik.setValues(res.data);

          // remove local storage
          if (localStorageKey) {
            localStorage.removeItem(localStorageKey);
          }
        }
      })
      .catch((err: any) => {
        // handle error
      });

    setDisabled(false);
    setLoading(false);
  };

  useEffect(() => {
    if (localStorageKey) {
      const storedValues = localStorage.getItem(localStorageKey);
      if (storedValues) {
        formik.setValues(JSON.parse(storedValues));
      }
    }
  }, []);

  useEffect(() => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(formik.values));
    }
  }, [formik.values]);

  const methods: FormikMethods = {
    disabled,
    isError: (key: string) => {
      return formik.touched?.[key] && formik.errors?.[key] ? true : false;
    },
    helperText: (key: string, text?: string) => {
      return formik.touched?.[key] && formik.errors?.[key] ? formik.errors?.[key] as string : text || '';
    },
    onChange: (e: React.ChangeEvent) => {
      alert('!')
      formik.handleChange(e)
    },
    setFieldValue: formik.setFieldValue,
  };

  const renderNotFieldError = () => {
    if (Object.keys(formik.errors).length === 0) return null;
    return (
      <Alert severity='error' sx={{ mb: 5 }}>
        <AlertTitle>Errors</AlertTitle>
        <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
      </Alert>
    );
  };

  if (loading) {
    return (
      <Stack spacing={5}>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="rounded" height={40} />
        ))}
      </Stack>
    );
  }

  return (
    <Box sx={{
      position: 'relative',
    }}>

      {/* {renderNotFieldError()} */}

      <CustomSnack
        enabled={props.showSnackbar || false}
        ref={refCustomSnack} />

      {/* localStorageKey: {props.localStorageKey} */}

      <Box
        noValidate
        autoComplete='off'
        component="form"
        onSubmit={(event: React.FormEvent) => {
          event.stopPropagation();
          event.preventDefault();
          formik.handleSubmit();
        }}>
        {props.children(formik, methods)}
      </Box>
    </Box>
  );
});

export default FormikForm;
