import React, { ErrorInfo, ReactNode } from 'react';

// mui
import { Box, Button, Container, Typography } from '@mui/material';

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    console.error({ error, errorInfo });
    this.setState(c => ({ ...c, error, errorInfo }));
  }

  render(): ReactNode {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="lg">
          <Box sx={{
            pt: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant="h2" align="center" gutterBottom>
              Oops, there is an error!
            </Typography>
            <Typography variant="body1" align="center">
              Something went wrong while rendering this component.
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ hasError: false })}
              >
                Try again?
              </Button>
            </Box>
            {(this.state.error || this.state.errorInfo) && (
              <Box component="pre" sx={{
                mt: 20,
                typography: 'caption',
                color: 'text.secondary',
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap',
                overflowX: 'auto',
              }}>
                {JSON.stringify(this.state, null, 2)}
              </Box>
            )}
          </Box>
        </Container>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
