import { useEffect, useReducer } from 'react'
import { useRouter } from 'next/router'

// reducers
import { Reducer, KEYS } from './reducer'
import { initialState, StateProps } from './initial'

// @core
import { useEstate } from '@core/api/'

// @configs
import routes from '@configs/routes'
import catalogCfg from '@configs/catalogCfg';

// types
import type { Currency } from './initial'


const actions = (): ActionProps => {

  // states
  const [state, dispatch]: [StateProps, any] = useReducer(Reducer, initialState)

  // hooks
  const estate = useEstate()
  const router = useRouter()

  // effects
  useEffect(() => {
    cls.fetchContext()
  }, [])


  // fetch properties for map view on filter change
  useEffect(() => {
    const { filter } = router.query

    // if (![routes.CATALOG.INDEX, routes.CATEGORIES.CATEGORY].includes(router.pathname)) return
    // if (routes.CATALOG.INDEX === router.pathname) {
    //   cls.resetFilters()
    //   return
    // }

    let filterSettings = (filter && typeof filter === 'string') ? JSON.parse(filter) : {}

    cls.updateFilterSettings(filterSettings)

  }, [router.pathname, router.query.filter])


  const cls = new class {
    get state() {
      return state
    }

    dispatchUpdate(payload: unknown) {
      dispatch({
        type: KEYS.UPDATE,
        payload,
      })
    }

    async fetchContext() {

      estate.CONTEXT.main().then(res => {
        if (res.status === 200) {
          const context = res.data

          dispatch({
            type: KEYS.UPDATE_CONTEXT,
            payload: { context },
          })

        } else {
          // console.error('Error fetching context')

          console.error('*'.repeat(100))
          console.error("FUCKING CORS")
          console.error('*'.repeat(100))
        }
      })
    }

    /**
     * Format prices & currencies
     */

    formatCurrency(value: number) {
      const { currency } = state

      if (typeof value !== 'number') {
        return value;
      }

      const number = parseFloat(value.toString().replace(/[$,]/g, ''));

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number);
    }

    formatCurrencyPretty(value: number) {
      const { currency } = state

      if (typeof value !== 'number') {
        return value;
      }

      const number = parseFloat(value.toString().replace(/[$,]/g, ''));

      if (number >= 1000000) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(number / 1000000) + 'M';
      } else if (number >= 1000) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number / 1000) + 'K';
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      }
    }

    calculatePrice(price: number | [number, number]) {
      const { context, currency } = state

      const calc = (v: number, price: number) => {
        let value = v

        if (currency !== 'USD') {
          const rate = context.currencies.find((c) => c.name === currency)?.usd_rate
          if (rate) {
            value = price * rate
          } else {
            value = price
          }
        }

        return this.formatCurrency(value)
      }


      if (Array.isArray(price)) {
        const [min, max] = price

        return `${calc(min, min)} - ${calc(max, max)}`
      }

      return calc(price, price)

    }

    get currencySymbol() {
      const { currency } = state

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
      }).formatToParts(0)[0].value
    }

    setCurrency(currency: Currency) {
      dispatch({
        type: KEYS.CHANGE_CURRENCY,
        payload: { currency },
      })
    }

    /**
     * Format unit
     */
    calculateArea(value: number) {

      let postfix = 'sqft'

      if (state.areaUnit === 'sqm') {
        value = value * 0.092903
        postfix = 'sqm'
      }

      const valueFormat = Math.round(value).toLocaleString()

      return `${valueFormat} ${postfix}`
    }

    setAreaUnit(unit: StateProps['areaUnit']) {
      dispatch({
        type: KEYS.CHANGE_AREA_UNIT,
        payload: { unit },
      })
    }

    /**
     * Google Map actions
     */

    setMapHoveredPointId(id: string | null) {
      dispatch({
        type: KEYS.UPDATE_MAP_HOVERED_POINT_ID,
        payload: { id },
      })
    }

    setMapShow() {
      dispatch({
        type: KEYS.UPDATE_MAP_SHOW,
        payload: {},
      })
    }

    /**
     * CATALOG
     */
    resetFilters() {

      dispatch({
        type: KEYS.UPDATE_FILTER_SETTINGS,
        payload: { filterSettings: initialState.filterSettings },
      })

      router.push({
        pathname: routes.CATALOG.INDEX,
        query: {
          reset: true,
        },
      })
    }

    updateFilterSettings(filterSettings: any) {
      dispatch({
        type: KEYS.UPDATE_FILTER_SETTINGS,
        payload: { filterSettings },
      })
    }

    applyFilters() {

      const { filterSettings } = state

      const newFilterSettings = {
        ...filterSettings,
        currency: this.state.currency,
      }

      const filter = JSON.stringify(newFilterSettings)
      if (filter === router.query.filter) return

      const newQuery = {
        page: 1,
        limit: catalogCfg.MAX_PRODUCT_PER_PAGE,
        filter,
      }

      router.push({
        pathname: routes.CATALOG.INDEX,
        query: {
          // ...router.query,
          ...newQuery,
        },
      })

      this.dispatchUpdate({
        filterSettingsLoaded: false,
      })

    }

    get filterLoaded() {
      return state.filterSettingsLoaded && state.contextLoaded
    }

  }

  return cls
}

export interface ActionProps {
  state: StateProps
  formatCurrency: (value: number) => string
  formatCurrencyPretty: (value: number) => string
  currencySymbol: string
  calculatePrice: (price: number | [number, number]) => string
  setCurrency: (currency: Currency) => void
  calculateArea: (value: number) => string
  setAreaUnit: (unit: StateProps['areaUnit']) => void
  setMapHoveredPointId: (id: string | null) => void
  setMapShow: () => void
  resetFilters: () => void
  updateFilterSettings: (filterSettings: any) => void
  applyFilters: () => void
  filterLoaded: boolean
}

export default actions