import { css } from '@emotion/react';


export const globalStyles = css({

  html: {
    // overflow: 'hidden',
    // overscrollBehavior: 'none',
  },
  body: {
    /* Disables pull-to-refresh but allows the page to scroll. */
    overscrollBehaviorYy: 'contain',
  },
  'strong, b': {
    fontWeight: '600 !important',
  }
})


export default globalStyles