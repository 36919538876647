import * as React from 'react';

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import { DialogProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

// icons
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface PropsHeader {
  title: string;
  closeDialog: () => void;
}

const Header = (props: PropsHeader) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">{props.title}</Typography>
      <IconButton
        sx={{
          mr: -2
        }}
        onClick={() => props.closeDialog()}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export interface DialogRefAccess {
  open: () => void;
  close: () => void;
  setTitle: (title: string) => void;
  setContent: (content: React.ReactNode) => void;
}

interface Props extends Omit<DialogProps, 'open'> {
  children?: React.ReactNode;
  title?: string;
  onOpen?: () => void;
  onClose: () => void;
  open?: boolean
}

const CustomDialog = React.forwardRef((props: Props, ref: React.Ref<DialogRefAccess>) => {

  // props
  const {
    children, title, onOpen, onClose,
    ...rest
  } = props;

  // hooks
  const media = useMedia();

  // states
  const [showContent, setShowContent] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState(title);
  const [content, setContent] = React.useState<React.ReactNode | null>(null);

  React.useImperativeHandle(ref, (): DialogRefAccess => ({
    open: () => {
      setShowContent(true);
      setDialogOpen(true);
      setDialogTitle(title)
      // setContent(props.children)
      props.onOpen && props.onOpen();
    },
    close: () => {
      setDialogOpen(false);
    },
    setTitle: (title: string) => {
      setDialogTitle(title);
    },
    setContent: (content: React.ReactNode) => {
      setContent(content);
    }
  }));

  // handlers
  const handleClose = () => {
    setDialogOpen(false);
    props.onClose();
  };

  return (
    <>
      <Dialog
        maxWidth={props.maxWidth || 'md'}
        sx={{
          ...props.sx,
          '& .MuiDialog-paper': {
            borderRadius: props.fullScreen ? {
              xs: 0,
              sm: 2,
            } : undefined
          }
        }}

        onClose={handleClose}
        fullWidth
        keepMounted={false}
        fullScreen={props.fullScreen && media.down.sm}
        TransitionComponent={Transition}
        onTransitionEnd={(event) => {
          if (!dialogOpen) {
            // console.log('hideContent')
            setShowContent(false)
            props.onClose()
          }
        }}
        {...rest}
        open={dialogOpen}
      >
        <DialogTitle sx={{
          py: 2
        }}>
          <Header title={dialogTitle || ''} closeDialog={handleClose} />
        </DialogTitle>
        <DialogContent dividers>
          {showContent && (content || props.children)}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default CustomDialog;
