
import type { IPropertySearch } from "@core/api/useEstate/types";


const catalogCfg = {
  MAX_PRODUCT_PER_PAGE: 30,
  DEFAULT_SORT: '-price' as IPropertySearch['ordering'],
};


export default catalogCfg;