// @core
import env from '@core/env'

const routes = {
  PAGES: {
    HOME: '/',

    PRIVACY: '/privacy/',
    TERMS: '/terms/',

    ABOUT: '/about/',
    BLOG: '/blog/',
  },
  CATALOG: {
    INDEX: '/real-estate/',
    COMPLEXESES: {
      INDEX: '/real-estate/complexes/',
      COMPLEX: '/real-estate/complexes/[slug]/',
    },
  },
  CATEGORIES: {
    INDEX: '/categories/',
    CATEGORY: '/real-estate/category/[slug]/',
  },
  DESTINATIONS: {
    INDEX: '/destinations/',
    COUNTRY: '/destinations/[country]/',
    SUBLOCATION: '/destinations/[country]/[sublocation]/',
  },
  DEVELOPERS: {
    DETAIL: '/developers/[slug]/',
  },
  AUTH: {
    SIGNIN: '/auth/signin/',
    SIGNOUT: '/api/auth/signout/',
    SIGNUP: '/auth/signup/',
    ERROR: '/auth/error/',
  },
  USER: {
    ACCOUNT: '/user/account/',
  },
}

export const routesPreset = {
  userLogged: routes.PAGES.HOME,
}

export default routes 