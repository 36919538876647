
interface Project {
  name: string
  static: {
    favicon: string
    ogImage: string
    noThumbnail: string
  }
  gtmId: string
  crispChatId: string
  contacts: {
    name: string
    email: string
    phone: string
    whatsapp: string
    facebook: string
    address: string
  }
  assets: {
    main: { [key: string]: any };
  }
}

const project: Project = {
  name: 'Сaribbean',
  static: {
    favicon: '/static/favicons/android-chrome-384x384.png',
    ogImage: '/static/og-image.jpg',
    noThumbnail: '/static/no-thumbnail.png',
  },
  gtmId: 'G-6TZS26B6KT',
  crispChatId: 'a438b4b2-48f0-445a-9749-386b45ce9127',
  contacts: {
    name: "Real Estate Marketing Solutions LLC",
    email: "info@caribbeanrealestatemls.com",
    phone: "(307) 225-9998",
    whatsapp: "https://wa.me/13072259998",
    facebook: "https://www.facebook.com/CaribbeanRealEstateMLS",
    address: `1603 Capitol Avenue, Suite 413-A #4229\n\nCheyenne, Wyoming\n82001, United States`,
  },
  assets: {
    main: {
      // videoPreview: '/static/main/video-preview.jpg',
      // videoPreviewXs: '/static/main/video-preview-xs.jpg',
      // demoLandscape: '/static/main/demo-landscape.png'
    }
  }
}

export default project