import { useImperativeHandle, forwardRef, useState } from 'react';

// mui
import { Box, Portal } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export interface CustomSnackRefAccesss {
  open: () => void;
}

interface Props {
  enabled: boolean;
}

const CustomSnack = forwardRef((props: Props, ref: React.Ref<CustomSnackRefAccesss>) => {

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, (): CustomSnackRefAccesss => ({
    open: () => handleClick(),
  }));

  const handleClick = () => {
    if (!props.enabled) return
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>

      {/* <Button onClick={() => handleClick()}>
        Up
      </Button> */}

      <Portal>
        <Snackbar
          open={open}
          onClose={handleClose}
          autoHideDuration={6000}
          TransitionComponent={TransitionUp}
          key={TransitionUp.name}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Done
          </Alert>
        </Snackbar>
      </Portal>

    </Box>
  );
})

export default CustomSnack