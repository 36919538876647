/* Usage

// @core
import useTranslate, {tDict} from '@core/i18n/useTranslate'

const demo = () => {

  const [tCommon] = useTranslate();
  const [tMain] = useTranslate('main');

  return (
    <div>
      {tCommon(tDict.common.title)}
      {tMain(tDict.main.title, {count: 1}})}
    </div>
  )
}
*/

// ** Third Party Import
import { useTranslation } from 'next-i18next';

// @core
import env from '@core/env'

// ** Local Import
import tDict from './translations'


type IUseTranslate = [
  (text: string, options?: any) => string,
  {
    i18n: any,
    locales: string[],
    languages: string[],
  }
]
const useTranslate = (namespace: string = 'common'): IUseTranslate => {

  // ** Hook
  const { t, i18n }: any = useTranslation(namespace);

  const locales: string[] = i18n.options?.locales || []
  const suffix: string = env.isProd ? '' : `_`
  const reT = (text: string, options?: any) => `${suffix}${t(text, options)}`


  const languages = locales.reduce((acc: any, locale: string) => {
    switch (locale) {
      case 'en':
        acc[locale] = 'English'
        break;
    }
    return acc;
  }, {})

  return [reT, {
    i18n,
    locales,
    languages,
  }]
}

export { tDict }
export default useTranslate
