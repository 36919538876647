

import * as React from 'react';

// @core
import { usePropertyContext } from '@core/context';

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Box, Popover, Typography, Button, ButtonGroup, Divider } from '@mui/material';

// snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';


export default function SettingsMenu() {

  // context
  const propertyContext = usePropertyContext();

  // hooks
  const media = useMedia();

  // states
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // computed
  const open = Boolean(anchorEl);
  const { state } = propertyContext;

  const handleClick = (event: React.MouseEvent<any, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box>

      <ButtonGroup
        variant="outlined" color="inherit"
        sx={{
          '& .MuiButton-root': {
            px: 2,
            textTransform: 'uppercase',
          },
        }}
        onClick={(e) => {
          handleClick(e)
        }}>
        <Button>
          {state.areaUnit}
        </Button>
        <Button>
          {state.currency}
        </Button>
      </ButtonGroup>

      <ThemeProviderMode mode="light">
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{
            p: 5,
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Metric
            </Typography>

            <ButtonGroup
              variant="outlined"
              size="small"
            >
              {state.areaMetrics.map((metric, index) => {
                const selected = metric === state.areaUnit

                return (
                  <Button
                    key={index}
                    variant={selected ? 'contained' : 'outlined'}
                    onClick={() => {
                      propertyContext.setAreaUnit(metric)
                      handleClose()
                    }}
                  >
                    {metric}
                  </Button>
                )
              })}
            </ButtonGroup>

            <Box sx={{
              pt: 5,
              pb: 3,
            }}>
              <Divider />
            </Box>

            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Currency
            </Typography>

            <ButtonGroup
              fullWidth
              variant="outlined"
              size="small"
              orientation={media.down.sm ? 'vertical' : 'horizontal'}>
              {state.context.currencies.map((currency, index) => {
                const selected = currency.name === state.currency

                return (
                  <Button
                    key={index}
                    variant={selected ? 'contained' : 'outlined'}
                    onClick={() => {
                      propertyContext.setCurrency(currency.name)
                      handleClose()
                    }}
                    sx={{
                      // px: 2,
                    }}
                  >
                    {currency.name}
                  </Button>
                )
              })}
            </ButtonGroup>

          </Box>
        </Popover>
      </ThemeProviderMode>

    </Box>
  );
}
