import { StateProps } from './initial'

const KEYS = {
  UPDATE: 'UPDATE',
  UPDATE_CONTEXT: 'UPDATE_CONTEXT',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY',
  CHANGE_AREA_UNIT: 'CHANGE_AREA_UNIT',
  // UPDATE_PROPERTIES_MAP: 'UPDATE_PROPERTIES_MAP',
  UPDATE_MAP_HOVERED_POINT_ID: 'UPDATE_MAP_HOVERED_POINT_ID',
  UPDATE_MAP_SHOW: 'UPDATE_MAP_SHOW',
  UPDATE_FILTER_SETTINGS: 'UPDATE_FILTER_SETTINGS',
}

function Reducer(state: StateProps, action: any) {
  const { type, payload: anValue } = action

  if (type === KEYS.UPDATE) {
    return { ...state, ...anValue }
  }

  if (type === KEYS.UPDATE_CONTEXT) {
    const { context } = anValue
    return { ...state, context, contextLoaded: true }
  }

  if (type === KEYS.CHANGE_CURRENCY) {
    const { currency } = anValue
    const findCurrency = state.context.currencies.find((item) => item.name === currency)

    if (findCurrency) {
      return { ...state, currency }
    }
  }

  if (type === KEYS.CHANGE_AREA_UNIT) {
    const { unit } = anValue
    return { ...state, areaUnit: unit }
  }

  // if (type === KEYS.UPDATE_PROPERTIES_MAP) {
  //   const { data } = anValue
  //   return { ...state, propertiesMap: data }
  // }

  if (type === KEYS.UPDATE_MAP_HOVERED_POINT_ID) {
    const { id } = anValue
    return { ...state, mapHoveredPointId: id }
  }

  if (type === KEYS.UPDATE_MAP_SHOW) {
    return { ...state, showMap: !state.showMap }
  }

  if (type === KEYS.UPDATE_FILTER_SETTINGS) {
    const { filterSettings } = anValue

    state.filterSettingsLoaded = true

    state.filterSettings = {
      ...state.filterSettings,
      ...filterSettings
    }

    return { ...state }
  }


  return state
}

export { Reducer, KEYS }
