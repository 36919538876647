import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

// @configs
import project from '@configs/project';
import routes from '@configs/routes';

// mui
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Collapse from '@mui/material/Collapse';

// icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// @snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';
import SvgLogo from '@snippets/Logotype/SvgLogo';

// @components
import { Link } from '@components/Link';

// data
import { mobileMenuList } from '../menuList';
import type { MobileMenuItem } from '../menuList';

interface Props {
  children: React.ReactNode;
}

export default function SwipeableTemporaryDrawer(props: Props) {
  const [open, setOpen] = useState(false);

  // hooks
  const router = useRouter();


  useEffect(() => {
    const handleRouteStart = () => {
      setOpen(false)
    }

    router.events.on("routeChangeStart", handleRouteStart);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
    };
  }, []);


  const toggleDrawer = (open: boolean) => (event: any) => {
    console.log('toggleDrawer', open)
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) { return; }

    setOpen(open);
  }


  return (
    <ThemeProviderMode mode="light">
      <Box>
        <Box onClick={() => setOpen(true)}>
          {props.children}
        </Box>
        <SwipeableDrawer
          keepMounted
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{
            width: {
              xs: 'calc(100vw - 40px)',
              sm: 300
            }
          }}>
            <Container setOpen={setOpen} />
          </Box>
        </SwipeableDrawer>
      </Box>
    </ThemeProviderMode>
  );
}


interface ContainerProps {
  setOpen: (open: boolean) => void
}

const Container = ({ setOpen }: ContainerProps) => {
  return (
    <Box sx={{
      p: 10
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 10
      }}>
        <SvgLogo
          href="/"
          sx={{
            width: 120,
          }}
        />
        <IconButton
          sx={{
            bgcolor: 'customColors.overlay',
            mr: -3,
          }}
          size="large"
          onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <SiteMenu />
      </Box>
    </Box>
  )
}

const SiteMenu = () => {

  return (
    <Box sx={{
      '& [data-menu="item"]': {
        display: 'flex',
        width: '100%',
        py: 2,
        fontSize: '1.1rem',
        fontWeight: 600,
        color: 'text.primary',
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.main',
        }
      }
    }}>

      {mobileMenuList.map((item, index) => {
        if (item?.divider) {
          return (
            <Box key={index} sx={{
              my: 5,
              borderTop: '1px solid',
              borderColor: 'divider',
            }} />
          )
        }

        if (item.items) {
          return (
            <Submenu key={index}
              showMoreHref={item.href || '#'}
              item={item} />
          )
        }

        return (
          <Box key={index}>
            <Link
              href={item?.href || '#'}
              data-menu="item"
            >
              {item.title}
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

interface SubmenuProps {
  item: MobileMenuItem
  showMoreHref: string;
}

const Submenu = ({ item, showMoreHref }: SubmenuProps) => {

  // states
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  // return (
  //   <pre>
  //     {JSON.stringify(item, null, 2)}
  //   </pre>
  // )

  const maxListSize = 6;
  const items = item.items || [];
  const list = items.slice(0, maxListSize);
  const hasMore = items.length > maxListSize;

  return (
    <Box>
      <Typography data-menu="item"
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {item.title}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s',
        }}>
          {open ? <RemoveIcon /> : <AddIcon />}
        </Box>
      </Typography>
      <Collapse in={open}>
        <Box sx={{
          pt: 2,
          pl: 5,
          mb: 5,
        }}>
          {list.map((item, index) => (
            <Box key={index}>
              <Link
                href={item.href || '#'}
                variant="body2"
                color="text.primary"
                underline="none"
                sx={{
                  display: 'block',
                  py: 2,
                  fontWeight: 400,
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                {item.title}
              </Link>
            </Box>
          ))}
          {hasMore && (
            <Box sx={{
              pt: 2,
              mt: 2,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}>
              <Link
                href={showMoreHref}
                variant="body2"
                color="text.primary"
                underline="none"
                sx={{
                  display: 'block',
                  py: 2,
                  fontWeight: (theme: any) => theme.typography.fontWeightBold,
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                See more
              </Link>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>

  )
}
