import Head from 'next/head'

// @configs
import project from '@configs/project'

// i18n
import { useTranslation } from 'next-i18next'

interface Props {
  ns: string;
  title?: string;
  description?: string;
  image?: string | null;
}

const SeoData = ({ ns, ...props }: Props) => {
  const [t] = useTranslation(ns)

  const image = props.image || project.static.ogImage

  const title = (() => {
    if (!props.title) return project.name
    return t(props.title).replace(/\n/g, ' ');
  })()

  const description = props.description ? t(props.description) : ''

  return (
    <Head>
      {title ? (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </>
      ) : ''}

      {description ? (
        <meta name="description" content={description} />
      ) : ''}

      {/* <meta property="og:image" content={image} /> */}

    </Head>
  )
}


export default SeoData