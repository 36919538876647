
// @core
import { useEstate } from "@core/api";

// ** MUI Imports
import { Stack, Box, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


// @components
import FormikForm, { FormikValues, FormikMethods, Yup } from '@components/Formik/'


interface Props {
  onSuccess: () => void
  contactFormId: number
}

const FormNext = ({ contactFormId, ...props }: Props) => {

  // hooks
  const estateApi = useEstate()

  const onSubmit = (values: any) => {

    // alert(JSON.stringify(values, null, 2))

    return estateApi.CONTACT_FORM.formUpdate(contactFormId, values).then((res) => {

      if (res.status === 200) {
        props.onSuccess()
      }

      return res
    })
  }

  return (
    <Box>

      {/* <Typography variant="h6" sx={{
        mb: 10,
      }}>
        To request detail... Feel like exploring the Dominican? Start the day with a hike on one of Playa Moron’s many trails.
      </Typography> */}

      <FormikForm
        initialValues={{
          visited: 'no',
          purchase: '',
          visit_arrange: 'yes',
          visit_date: '',
          budget: '',
          subscribe: 'yes',
        }}
        validationSchema={Yup.object({
          visited: Yup.string().required('Required'),
          purchase: Yup.string().required('Required'),
          visit_arrange: Yup.string().required('Required'),
          visit_date: Yup.string().required('Required'),
          budget: Yup.string().required('Required'),
          subscribe: Yup.string().required('Required'),
        })}
        readOnLoad={false}
        onSubmit={onSubmit}
        onRead={undefined}
      >
        {(formik: FormikValues, methods: FormikMethods) => (
          <Stack spacing={10}>


            <Box>
              <Typography variant="h5" gutterBottom>
                Have you visited the location before?
              </Typography>

              <RadioGroup
                row
                name="visited"
                value={formik.values.visited}
                onChange={(e) => {
                  // formik.handleChange(e)
                  methods.setFieldValue('visited', e.target.value)
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography variant="h5" gutterBottom>
                How soon are you looking to purchase?
              </Typography>

              <TextField
                fullWidth
                required
                name="purchase"
                type="text"
                placeholder=""
                disabled={methods.disabled}
                value={formik.values.purchase}
                onChange={formik.handleChange}
                error={methods.isError('purchase')}
                helperText={methods.helperText('purchase')}
              />
            </Box>

            <Box>
              <Typography variant="h5" gutterBottom>
                Would you like us to arrange a visit?
              </Typography>

              <RadioGroup
                row
                name="visit_arrange"
                value={formik.values.visit_arrange}
                onChange={(e) => {
                  // formik.handleChange(e)
                  methods.setFieldValue('visit_arrange', e.target.value)
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>

            <Box>
              <Typography variant="h5" gutterBottom>
                When would you like to visit?
              </Typography>

              <TextField
                fullWidth
                required
                name="visit_date"
                type="text"
                placeholder=""
                disabled={methods.disabled}
                value={formik.values.visit_date}
                onChange={formik.handleChange}
                error={methods.isError('visit_date')}
                helperText={methods.helperText('visit_date')}
              />
            </Box>

            <Box>
              <Typography variant="h5" gutterBottom>
                What is your maximum budget?
              </Typography>

              <TextField
                fullWidth
                required
                name="budget"
                type="text"
                placeholder=""
                disabled={methods.disabled}
                value={formik.values.budget}
                onChange={formik.handleChange}
                error={methods.isError('budget')}
                helperText={methods.helperText('budget')}
              />
            </Box>

            <Box>
              <Typography variant="h5" gutterBottom>
                Would you like to receive details of somlar properties, updates & news via e-mail from Caribbean Real Estate MLS?
              </Typography>

              <RadioGroup
                row
                name="subscribe"
                value={formik.values.subscribe}
                onChange={(e) => {
                  // formik.handleChange(e)
                  methods.setFieldValue('subscribe', e.target.value)
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>


            <Button
              type="submit"
              color="primary"
              size="large"
              variant="contained" disabled={methods.disabled}>
              Confirm
            </Button>

          </Stack>
        )}

      </FormikForm>

    </Box>
  )
}

export default FormNext
