import * as React from 'react';
import useRouter from '@hooks/useRouter';
import { useSession } from 'next-auth/react';
import NextLink from 'next/link';

// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";
import { PageProps } from "@core/i18n/pageService/types"

// @hooks
import useMedia from '@hooks/useMedia';

// @configs
import project from '@configs/project';
import routes from '@configs/routes';

// mui
import { alpha } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';

// snippets
import SeoData from '@core/snippets/SeoData'
import SvgLogo from '@snippets/Logotype/SvgLogo';
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';

// data
import { menuList } from './menuList';

// blocks
import MobileMenu from './MobileMenu';
import Submenu, { SubmenuRefAccess } from './Submenu'

import SettingsMenu from './SettingsMenu';
import RequestCall from './RequestCall';


interface Props extends PageProps {
  children: React.ReactNode;
}

function ResponsiveAppBar(props: Props) {

  // refs
  const refSubmenu = React.useRef<SubmenuRefAccess>(null)

  // states
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  // hooks
  const theme = useTheme<Theme>()
  const router = useRouter()
  const media = useMedia()

  const [t] = useTranslate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateTo = (url: string) => (e: React.MouseEvent<HTMLElement>) => {
    router.push(url)
    handleCloseNavMenu()
  }

  const appBarTransparent = props.config?.appBar?.bgcolor === 'transparent'
  const appBarPosition = props.config?.appBar?.position || 'sticky'



  return (
    <Box sx={{
      position: 'relative',
    }}>

      <ThemeProviderMode mode={appBarTransparent ? 'dark' : 'auto'}>
        <AppBar
          sx={{
            position: appBarPosition,
            top: 0,
            py: {
              sm: 1,
              md: 2,
            },
            zIndex: 'appBar',
            bgcolor: theme => {
              if (appBarTransparent) return 'transparent'
              // return alpha(theme.palette.background.default, 0.6)
              return theme.palette.background.paper
            },
            borderBottom: theme => {
              if (appBarTransparent) return '1px solid rgba(255, 255, 255, 0.1)'
              return `1px solid ${theme.palette.divider}`
            },
            color: appBarTransparent ? 'common.white' : 'text.primary',
            boxShadow: 'none',
            // backdropFilter: 'blur(10px)',
          }}>
          <Container
            maxWidth={false}
            sx={{
              px: {
                xs: 5,
                // xs: 'none',
              }
            }}>
            <Toolbar disableGutters>

              <Box sx={{
                mr: 10,
                py: 2,
                // ml: 3,
                display: { xs: 'none', md: 'flex' },
              }}>
                {/* <Typography
                
                color="inherit"
                variant="h5"
                noWrap>
                {project.name}
              </Typography> */}
                <SvgLogo
                  component={NextLink}
                  href="/"
                  sx={{
                    width: 150,
                  }}
                  color={appBarTransparent ? '#fff' : theme.palette.primary.main}
                />
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>

                <MobileMenu>
                  <IconButton
                    size="large"
                    color="inherit"
                    sx={{
                      ml: -2,
                      mr: 2,
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </MobileMenu>

              </Box>
              <Box sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                justifyContent: 'flex-start',
              }}>
                {/* <Typography
                component={NextLink}
                href="/"
                color="inherit"
                variant="h5"
                noWrap>
                {project.name}
              </Typography> */}
                <SvgLogo
                  component={NextLink}
                  href="/"
                  sx={{
                    width: 130,
                  }}
                  color={appBarTransparent ? '#fff' : theme.palette.primary.main}
                />
              </Box>
              <Box sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}>
                {menuList.map((page, index) => (
                  <Button
                    key={index}
                    endIcon={page.variant ? <ExpandMoreIcon /> : null}
                    onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                      if (page.variant) {
                        refSubmenu.current?.open(event, page.variant)
                      } else {
                        refSubmenu.current?.close()
                      }
                    }}
                    LinkComponent={NextLink}
                    href={page.href || '#'}
                    variant={router.pathname.startsWith(page.href) ? 'outlined' : 'text'}
                    color="inherit"
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '1rem',
                      fontWeight: 600,
                      minWidth: 'auto',
                      my: 2,
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>

              <Box sx={{
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                gap: 5,
              }}>

                <SettingsMenu />
                {media.up.md && (
                  <RequestCall>
                    <Button variant="contained" color="primary" disableElevation
                      sx={{
                        boxShadow: 'none',
                      }}>
                      Request a Call
                    </Button>
                  </RequestCall>
                )}
                {/* <LaguageMenu />
              <AccoutMenu /> */}
              </Box>

            </Toolbar>

          </Container>


          <Submenu ref={refSubmenu} />

        </AppBar>
      </ThemeProviderMode>

      <Box
        sx={{
          flexGrow: 1,
        }}>
        <ChildrenContainer {...props}>
          <ProtectedContent {...props} />
        </ChildrenContainer>
      </Box>

      <SeoData
        ns="common"
        title={props.config?.seo?.title || project.name}
        description={props.config?.seo?.description}
        image={props.config?.seo?.image}
      />

    </Box>
  );
}

const ChildrenContainer = (props: Props) => {

  if (props.config?.appBar?.noContainer === true) {
    return (
      <>
        {props.children}
      </>
    )
  }

  return (
    <Container maxWidth="xl" sx={{
      py: {
        xs: 5,
        sm: 10,
      },
    }}>
      {props.children}
    </Container>
  )
}

const ProtectedContent = (props: Props) => {

  // hooks
  const router = useRouter()
  const { data: session, status } = useSession()

  React.useEffect(() => {

    if (props.config?.protected && status === 'unauthenticated') {
      router.push({
        pathname: routes.AUTH.SIGNIN,
        query: {
          callbackUrl: router.pathname,
        },
      })
    }

  }, [status, router.pathname])

  if (props.config?.protected && !session) {
    return <div />
  }

  return (
    <>
      {props.children}
    </>
  )
}


const RootLayout = (props: Props) => {

  return (
    <ResponsiveAppBar {...props} />
  )
}

export default RootLayout;
