
// types
import type { FilterSettings } from './types'

import type {
  ResponseContextResults,
  ResponseContextCurrency,
} from '@core/api/useEstate/types'

export type Currency = ResponseContextCurrency['name']


export interface StateProps {
  currency: Currency;
  areaUnit: 'sqft' | 'sqm';
  areaMetrics: Array<'sqft' | 'sqm'>;
  context: ResponseContextResults;
  contextLoaded: boolean;
  mapHoveredPointId: string | null;
  showMap: boolean;
  filterSettingsLoaded: boolean;
  filterSettings: FilterSettings;
}

export const initialState: StateProps = {
  currency: "USD",
  areaUnit: 'sqft',
  areaMetrics: ['sqft', 'sqm'],
  contextLoaded: false,
  context: {
    tags: [],
    types: [],
    locations: [],
    prices: {
      min: 0,
      max: 0,
    },
    currencies: [
      {
        name: "USD",
        usd_rate: 1,
      },
    ]
  },
  mapHoveredPointId: null,
  showMap: true,

  filterSettingsLoaded: false,
  filterSettings: {
    tags: [],
    locations: [],
    types: [],
    price: [0, 0],
    bedrooms: [],
    bathrooms: [],
    currency: undefined
  }
}