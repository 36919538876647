
import { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';

// @core
import { useGlobalContext } from '@core/context';

// mui
import { Box, Button, Typography } from '@mui/material'

// @components
import Dialog, { DialogRefAccess } from '@components/Dialog';

// snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';


// blocks
import Form from './Form';

export interface DialogSignupRefAccess {
  open: () => void;
}

interface Props {
  children: React.ReactNode;
  propertyId?: string;
}

const DialogRequest = forwardRef((props: Props, ref: React.Ref<DialogSignupRefAccess>) => {

  // context
  const { userIsLogged } = useGlobalContext()

  // refs
  const refDialog = useRef<DialogRefAccess>(null)

  // states
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    if (userIsLogged) {
      refDialog.current?.close()
    }
  }, [userIsLogged])

  useImperativeHandle(ref, (): DialogSignupRefAccess => ({
    open: () => {
      refDialog.current?.open()
    }
  }));

  return (
    <ThemeProviderMode mode="light">

      <Dialog
        ref={refDialog}
        maxWidth='sm'
        title="Request a Callback & Property Delails"
        onClose={() => { }}
      >
        <Box sx={{
          p: 5,
        }}>
          {success ? (
            <Box>
              <Typography variant="h4" align="center" gutterBottom>
                Thank you for your request!
              </Typography>
              <Typography variant="h6" align="center">
                We will contact you shortly.
              </Typography>
            </Box>
          ) : (
            <Form
              propertyId={props.propertyId}
              onSuccess={() => {
                setSuccess(true)
              }} />
          )}

        </Box>

      </Dialog>

      <div onClick={() => {
        refDialog.current?.open()
      }}>
        {props.children}
      </div>

    </ThemeProviderMode>
  )
})

export default DialogRequest