import * as React from "react";
import { useRouter } from 'next/router';

// snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';

// mui
import { keyframes } from "@mui/material";
import { Container } from "@mui/material";
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

// blocks
import Company from "./Company";
import Services from "./Services";
import Buying from "./Buying";
import Articles from "./Articles";

export interface SubmenuRefAccess {
  open: (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => void;
  close: () => void;
}

interface Props {

}

export type SubmenuVariant = 'buying' | 'company' | 'services' | 'articles'

const Submenu = React.forwardRef((props: Props, ref: React.Ref<SubmenuRefAccess>) => {

  // refs
  const refTimeout = React.useRef<NodeJS.Timeout | null>(null)

  // states
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [variant, setVariant] = React.useState<null | SubmenuVariant>(null)

  // hooks
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteStart = () => {
      handleClose()
    }

    router.events.on("routeChangeStart", handleRouteStart);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    setAnchorEl(event.currentTarget);
    setVariant(variant)
  };

  const handleClose = () => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    setAnchorEl(null);
  };

  const handleMouseOut = () => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    refTimeout.current = setTimeout(() => {
      handleClose()
    }, 500)
  }

  const open = Boolean(anchorEl);


  React.useImperativeHandle(ref, (): SubmenuRefAccess => ({
    open: (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => {
      handleClick(event, variant)
    },
    close: () => {
      handleClose()
    },
  }))

  return (
    <ThemeProviderMode mode="light">
      <Box>

        <ClickAwayListener onClickAway={(event) => {
          if (event.target === anchorEl) return
          handleClose()
        }}>

          <Box
            data-show={open}
            onMouseLeave={handleMouseOut}
            sx={{
              bgcolor: 'background.paper',
              color: 'text.primary',
              boxShadow: 3,
              zIndex: theme => theme.zIndex.appBar + 1,
              position: 'absolute',
              top: 80,
              left: 0,
              right: 0,
              borderTop: '1px solid',
              borderColor: 'divider',
              // animation: open ? `${slideFromTop} 0.5s ease forwards` : '',

              '&[data-show="true"]': {
                display: 'block',
              },
              '&[data-show="false"]': {
                display: 'none',
              },
            }}>
            <Container maxWidth="xl">
              <Box sx={{
                py: 10,
              }}>
                {variant === 'buying' && <Buying />}
                {variant === 'company' && <Company />}
                {variant === 'services' && <Services />}
                {variant === 'articles' && <Articles />}
              </Box>
            </Container>
          </Box>


          {/* <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onMouseEnter={(event) => {
            clearTimeout(refTimeout.current as NodeJS.Timeout)
          }}
          onMouseLeave={handleMouseOut}
          elevation={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              pointerEvents: 'all',
              border: 0,
              maxWidth: 600,
              boxShadow: 2,
            },
          }}
        >
          {variant === 'company' && <Company />}

        </Popover> */}

        </ClickAwayListener>

      </Box>
    </ThemeProviderMode>
  )
})

// const slideFromTop = keyframes(`
//   from {
//     transform: translateY(-100%);
//   }
//   to {
//     transform: translateY(0);
//   }
// `)



export default Submenu