
// @configs
import routes from "@configs/routes";
import project from "@configs/project";

// mui
import Box from '@mui/material/Box';

// @components
import { Link } from '@components/Link'
import { Typography } from "@mui/material";

import { menuServices } from '../menuList'


export default function Services() {

  const items = menuServices

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 5,
    }}>
      {items.map((item, index) => (
        <Box
          key={index}
          href={item.href}
          component={Link}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            cursor: 'pointer',
            color: 'text.primary',
            bgcolor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: 1,
            px: 5,
            py: 10,
          }}>
          <Box>
            <Typography variant="h5" color="inherit" align="center" gutterBottom sx={{
              textTransform: 'uppercase',
              fontSize: '1.0rem',
            }}>
              {item.title}
            </Typography>
            <Typography variant="subtitle1" color="inherit" align="center">
              {item.subtitle}
            </Typography>
          </Box>

          <Typography
            variant="subtitle1" color="primary" align="center"
            sx={{
              mt: 5,
              fontWeight: 'bold',
            }}>
            Learn More
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
