// @configs
import routes from "@configs/routes";
import project from "@configs/project";



// types
import type { SubmenuVariant } from './Submenu'

export type MenuItem = {
  title: string;
  href: string;
  variant?: SubmenuVariant;
}


export const menuList: Array<MenuItem> = [
  {
    title: 'Buying',
    href: routes.CATALOG.INDEX,
    variant: 'buying',
  },
  {
    title: 'Selling',
    href: '/blog/services/selling',
  },
  {
    title: 'Communities',
    href: routes.CATALOG.COMPLEXESES.INDEX,
  },
  {
    title: 'Destinations',
    href: routes.DESTINATIONS.INDEX,
  },
  {
    title: 'Articles',
    href: routes.PAGES.BLOG,
    variant: 'articles',
  },
  // {
  //   title: 'Complexes',
  //   href: routes.CATALOG.COMPLEXESES.INDEX,
  // },
  // {
  //   title: 'Destinations',
  //   href: routes.DESTINATIONS.INDEX,
  // },
  // {
  //   title: 'Categories',
  //   href: routes.CATEGORIES.INDEX,
  // },

  {
    title: 'Services',
    href: '/blog/services',
    variant: 'services',
  },
  {
    title: 'Company',
    href: routes.PAGES.ABOUT,
    variant: 'company',
  },
]




export type MobileMenuItem = {
  title: string;
  href?: string;
  items?: Array<MenuItem>;
  divider?: boolean;
}

export const mobileMenuList: Array<MobileMenuItem> = [
  {
    title: 'Home',
    href: routes.PAGES.HOME,
  },
  {
    title: '',
    divider: true,
  },
  {
    title: 'Buying',
    href: routes.CATALOG.INDEX,
  },
  {
    title: 'Selling',
    href: '/blog/services/selling',
  },
  {
    title: 'Communities',
    href: routes.CATALOG.COMPLEXESES.INDEX,
  },
  {
    title: 'Destinations',
    href: routes.DESTINATIONS.INDEX,
  },
  {
    title: 'Categories',
    href: routes.CATEGORIES.INDEX,
  },
  {
    title: '',
    divider: true,
  },
  {
    title: 'Articles',
    href: routes.PAGES.BLOG,
  },
  {
    title: '',
    divider: true,
  },
  {
    title: 'Services',
    href: '#',
  },
  {
    title: 'Company',
    href: routes.PAGES.ABOUT,
  },
]

type MenuCompanyItem = {
  title: string;
  subtitle: string;
  icon: string;
  href: string;
}

export const menuCompany: MenuCompanyItem[] = [
  {
    title: 'About the Company',
    subtitle: 'What we\'re all about',
    icon: 'mdi:information-outline',
    href: routes.PAGES.ABOUT,
  },
  {
    title: 'Contact Us',
    subtitle: 'We\'re here to serve you',
    icon: 'mdi:phone-outline',
    href: '/blog/contact-us',
  },
  {
    title: 'FAQ\'s',
    subtitle: 'Your questions answered',
    icon: 'mdi:comment-question-outline',
    href: '/blog/frequently-asked-questions',
  },
  {
    title: 'Newsletter',
    subtitle: 'Get free email updates',
    icon: 'mdi:email-outline',
    href: '/blog/newsletter',
  },
]


type MenuArticlesItem = {
  title: string;
  links: {
    label: string;
    href: string;
  }[];
}

export const menuArticles: MenuArticlesItem[] = [
  {
    title: 'Destination Guides',
    links: [
      {
        label: 'Affordable Beachfront Locations',
        href: '/blog/affordable-caribbean-beachfront-locations',
      },
      {
        label: 'How to Buy Property in the Caribbean',
        href: '/blog/how-to-buy-property-in-the-caribbean',
      },
      {
        label: 'Safest Islands in the Caribbean',
        href: '/blog/safest-islands-in-the-caribbean',
      },
      {
        label: 'Top Communities for Wealthy Expats',
        href: '/blog/best-communities-for-families-in-the-caribbean',
      },
      {
        label: 'Undiscovered Gems in the Caribbean',
        href: '/blog/undiscovered-gems-in-the-caribbean',
      },
    ]
  },
  {
    title: 'Real Estate Investing',
    links: [
      {
        label: 'Caribbean Investing Guide',
        href: '/blog/caribbean-real-estate-investing-guide',
      },
      {
        label: 'Top Investment Opportunities',
        href: '/blog/top-investment-opportunities',
      },
      {
        label: 'Real Estate Market Trends',
        href: '/blog/caribbean-real-estate-market-trends',
      },
      {
        label: 'Best New Communities to Invest',
        href: '/blog/best-new-developments-in-the-caribbean',
      },
      {
        label: 'Vacation Rental and Airbnb Guide',
        href: '/blog/caribbean-airbnb-rental-guide',
      },
    ]
  },
  {
    title: 'Caribbean Lifestyle',
    links: [
      {
        label: 'Top Family Friendly Destinations',
        href: '/blog/caribbean-family-friendly-destinations',
      },
      {
        label: 'Caribbean Travel Guide',
        href: '/blog/comprehensive-caribbean-travel-guide',
      },
      {
        label: 'Best Restaurants in the Caribbean',
        href: '/blog/best-restaurants-in-the-caribbean',
      },
      {
        label: 'Boating in the Caribbean Sea',
        href: '/blog/top-boating-destinations-in-the-caribbean',
      },
      {
        label: 'Living Tax-Free in the Caribbean',
        href: '/blog/living-tax-free-in-the-caribbean',
      },
    ]
  },
  {
    title: ' Investment Migration',
    links: [
      {
        label: 'Caribbean Citizenship by Investment',
        href: '/blog/caribbean-citizenship-by-investment-guide',
      },
      {
        label: 'Top Caribbean Golden Visa Programs',
        href: '/blog/top-caribbean-golden-visa-programs',
      },
      {
        label: 'Tax Residence and Planning',
        href: '/blog/how-to-get-tax-residence-in-the-caribbean',
      },
      {
        label: 'Best Tax Neutral Jurisdictions',
        href: '/blog/best-low-tax-countries-in-the-caribbean',
      },
      {
        label: 'Living and Investing in the Caribbean',
        href: '/blog/living-and-investing-in-the-caribbean',
      },
    ]
  }

]




type MenuBuyingItem = {
  title: string;
  links: {
    label: string;
    href: string;
  }[];
}

export const menuBuying: MenuBuyingItem[] = [
  {
    title: 'Caribbean Real Estate',
    links: [
      {
        label: 'Beachfront Homes for Sale',
        href: '/real-estate/category/caribbean-beachfront-homes-for-sale/',
      },
      {
        label: 'Caribbean Condos for Sale',
        href: '/real-estate/category/caribbean-condos-for-sale/',
      },
      {
        label: 'Homes Under $300,000',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[26%2C33%2C67%2C74%2C76%2C78%2C79%2C90%2C112%2C119%2C127%2C137%2C149%2C156%2C158%2C188%2C203]%2C"types"%3A[9%2C5]%2C"price"%3A[0%2C300000]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Caribbean Land for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[]%2C"types"%3A[3]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Private Islands for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[]%2C"types"%3A[4]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
    ]
  },
  {
    title: 'Top Destinations',
    links: [
      {
        label: 'Mexican Real Estate for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[2%2C80%2C95%2C100%2C129%2C134%2C138%2C140%2C200%2C216]%2C"types"%3A[]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Property for Sale in Jamaica',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[31%2C338%2C339%2C340%2C77%2C86%2C341%2C342%2C343%2C103%2C109%2C111%2C130%2C146%2C346%2C347%2C348%2C349%2C350%2C171%2C351%2C199%2C212]%2C"types"%3A[]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Turks and Caicos Real Estate',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[12%2C410%2C411%2C412%2C413%2C414%2C415%2C416%2C417%2C418%2C419%2C420%2C421%2C422%2C423%2C424%2C425%2C426%2C427%2C108%2C428%2C121%2C429%2C132%2C430%2C431%2C432%2C160%2C433%2C166%2C434%2C435%2C201%2C436%2C437%2C438]%2C"types"%3A[]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Central America Real Estate',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[17%2C35%2C39%2C125%2C152%2C195%2C26%2C33%2C67%2C74%2C76%2C78%2C79%2C90%2C112%2C119%2C127%2C137%2C149%2C156%2C158%2C188%2C203%2C9%2C65%2C139%2C151%2C4%2C45%2C60%2C118%2C123%2C124%2C126%2C136%2C157%2C22%2C38%2C75%2C117%2C122%2C154%2C208%2C210]%2C"types"%3A[]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Homes for Sale in The Bahamas',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[16%2C32%2C227%2C34%2C36%2C228%2C229%2C42%2C43%2C230%2C231%2C232%2C54%2C233%2C234%2C235%2C236%2C237%2C238%2C239%2C63%2C64%2C240%2C242%2C68%2C243%2C244%2C245%2C72%2C246%2C247%2C248%2C250%2C251%2C85%2C252%2C253%2C254%2C94%2C255%2C96%2C97%2C256%2C257%2C104%2C110%2C114%2C258%2C259%2C120%2C260%2C261%2C262%2C142%2C144%2C155%2C153%2C263%2C264%2C265%2C169%2C267%2C268%2C197%2C269%2C270%2C271%2C272%2C215%2C273]%2C"types"%3A[]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
    ]
  },
  {
    title: 'New Development',
    links: [
      {
        label: 'New Caribbean Condos for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[]%2C"types"%3A[6%2C8%2C14]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Pre-Construction Developments',
        href: routes.CATALOG.COMPLEXESES.INDEX,
      },
      {
        label: 'New Homes in Cayman Islands',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[]%2C"locations"%3A[7%2C310%2C55%2C311%2C312%2C314%2C73%2C315%2C93%2C316%2C318%2C319%2C145%2C320%2C163%2C321%2C322%2C206%2C207%2C323]%2C"types"%3A[1%2C9%2C5%2C14]%2C"price"%3A[0%2C0]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Best New Luxury Villas for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[5]%2C"locations"%3A[]%2C"types"%3A[9%2C14]%2C"price"%3A[1500000%2C0]%2C"bedrooms"%3A[3]%2C"bathrooms"%3A[2]%2C"currency"%3A"USD"}',
      },
      {
        label: 'Best Beachfront Lots for Sale',
        href: '/real-estate/?page=1&limit=30&filter={"tags"%3A[8%2C3]%2C"locations"%3A[]%2C"types"%3A[3]%2C"price"%3A[500000%2C4000000]%2C"bedrooms"%3A[]%2C"bathrooms"%3A[]%2C"currency"%3A"USD"}',
      },
    ]
  },
  {
    title: 'Services for Buyers',
    links: [
      {
        label: 'Real Estate Investment Consulting',
        href: '/blog/services/real-estate-consulting',
      },
      {
        label: 'Property and Project Management',
        href: '/blog/services/property-management',
      },
      {
        label: 'Citizenship by Investment',
        href: '/blog/services/caribbean-citizenship-by-investment',
      },
      {
        label: 'Relocation Services',
        href: '/blog/services/relocation',
      },
      {
        label: 'Tax Planning and Reduction',
        href: '/blog/services/tax-planning',
      },
    ]
  }

]



type MenuServicesItem = {
  title: string;
  subtitle: string;
  href: string;
}

export const menuServices: MenuServicesItem[] = [
  {
    title: 'All Services',
    subtitle: 'Click here to see all products and services we offer.',
    href: '/blog/services',
  },
  {
    title: 'Real Estate Consulting',
    subtitle: 'Use our decades of experience to invest in the Caribbean real estate.',
    href: '/blog/services/real-estate-consulting',
  },
  {
    title: 'Investment Migration',
    subtitle: 'Get a second passport and enjoy visa-free travel to over 100 countries.',
    href: '/blog/services/investment-migration',
  },
  {
    title: 'Advertising',
    subtitle: 'Thousands of new home buyers visit our site. Be sure they see your listings.',
    href: '/blog/services/advertising',
  },
]