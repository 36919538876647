// @core
import env from '@core/env'

// libs
import { getSession, signOut } from "next-auth/react";
import axios, { AxiosInstance, AxiosResponse } from 'axios';

type MethodsOptions = {
  multipart?: boolean;
  timeout?: number;
}

type ResponsePromises = Promise<{
  data: any;
  status: number;
}>

class AxiosApi {
  axios: AxiosInstance;

  constructor(ssrLocale?: string) {

    const axiosOptions = {
      // baseURL: Env.API_URL,
      timeout: 10000,
      headers: {
        'Accept': 'application/json',
        'Accept-Language': 'en', // TODO: get from server
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    this.axios = axios.create(axiosOptions);

    this.axios.interceptors.request.use(
      async (config) => {
        const session: any = await getSession();

        // Enrich request with server information
        config.headers['Accept-Language'] = ssrLocale || 'en';

        if (session) {
          config.headers['Authorization'] = `Bearer ${session.access}`;
          config.headers['Refresh'] = session.refresh;
        }

        // Enrich request with client information
        if (typeof window !== 'undefined') {

          // config.headers['X-Forwarded-Host'] = window.location.host;
          // config.headers['X-Forwarded-Proto'] = window.location.protocol;
          // config.headers['X-Forwarded-Port'] = window.location.port;
          // config.headers['X-Forwarded-For'] = window.location.hostname;
          config.headers['Accept-Language'] = localStorage.getItem('NEXT_LOCALE') || window.navigator.language || 'en';
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return {
          ok: response.status === 200,
          ...response,
        };
      },
      async (error) => {

        // "Token expired"
        if (error.response?.status === 401) {
          await signOut();
          window.location.href = "/auth/signin";
        }

        return Promise.resolve({
          ok: false,
          ...(error.response as AxiosResponse),
        });
      }
    );
  }

  url(url: string) {

    // TODO: remove this
    // return env.API_URL + url;

    if (env.isDev) {
      return env.API_URL + url;
    }

    // For server side rendering
    if (typeof window === 'undefined') {
      return env.API_URL + url;
    }

    return url;
  }

  setOptions(options?: any) {
    if (options?.multipart) {
      this.axios.defaults.headers['Content-Type'] = 'multipart/form-data';
    }
    if (options?.timeout) {
      // const axiosOptions = {
      //   ...this.axios.defaults,
      //   timeout: options.timeout,
      // }
      this.axios.defaults.timeout = options.timeout;
      // this.axios.interceptors
      // this.axios = axios.create(axiosOptions);
      // console.log(this.axios.defaults.timeout)
    }
    // this.axios.defaults.headers = {
    //   ...this.axios.defaults.headers,
    //   ...options,
    // };
  }

  public post(url: string, body: any, options?: MethodsOptions): ResponsePromises {
    this.setOptions(options);
    url = this.url(url);
    return this.axios.post(url, body);
  }

  public get(url: string, query: any = false, options?: MethodsOptions): ResponsePromises {
    this.setOptions(options);

    if (query && typeof query === 'object') {
      const queryString = new URLSearchParams(query).toString();
      url = `${url}?${queryString}`;
    }

    url = this.url(url);
    return this.axios.get(url);
  }

  public put(url: string, body: any, options?: MethodsOptions): ResponsePromises {
    this.setOptions(options);

    url = this.url(url);
    return this.axios.put(url, body);
  }

  public patch(url: string, body: any): ResponsePromises {
    url = this.url(url);
    return this.axios.patch(url, body);
  }

  public delete(url: string): ResponsePromises {
    url = this.url(url);
    return this.axios.delete(url);
  }
}

export default AxiosApi;