
// i18n
import { PageProps } from "@core/i18n/pageService/types"

// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";

// @configs
import project from '@configs/project';

// mui
import { Box } from "@mui/material";

// snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';

// blocks
import AppBar from './AppBar';
import Footer from '../Footer';

// @components
// import CrispChat from "@components/CrispChat";


interface AppLayoutProps {
  children: React.ReactNode
  config?: PageProps['config']
}

const AppLayout = ({ config, ...props }: AppLayoutProps) => {

  // hooks
  const [t] = useTranslate();


  if (!config) {
    return (
      <>
        {props.children}
      </>
    )
  }

  if (config) {

    if (config?.blank) {
      return (
        <>
          {props.children}
        </>
      )
    }

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>

        {/* {config?.plugins?.chat && (
          <CrispChat
            apiKey={project.crispChatId}
            welcomeMessage={t(tDict.common.snippets.chat.welcomeMessage)}
            position="right"
            color="blue"
          />
        )} */}

        <Box sx={{
          flex: '1 1 auto',
        }}>
          <AppBar config={config}>
            <>
              {props.children}
            </>
          </AppBar>
        </Box>
        <ThemeProviderMode mode="dark">
          <Footer size={config?.footer?.size} />
        </ThemeProviderMode>
      </Box>
    )
  }

}


export default AppLayout